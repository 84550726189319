<script setup>
	import { computed } from 'vue';
	import { DsText } from '@coloplast/design-system';
	import VideoItemLoop from 'shared/components/VideoElements/videoItemLoop.vue';

	const props = defineProps({
		vm: {
			type: Object,
			default: () => {},
		},
	});

	const formattedHeadline = computed(() => {
		let div = document.createElement('div');
		div.innerHTML = props.vm.CurrentBlock.Headline;
		return div.textContent || div.innerText || '';
	});
</script>

<template>
	<section class="c-rich-text-cta-banner">
		<picture
			v-if="!vm.CurrentBlock.VideoUrl && vm.BaseViewModel.DesktopImageUrl"
			class="c-rich-text-cta-banner__media"
		>
			<source
				v-if="vm.BaseViewModel.MobileImageUrl"
				media="(max-width: 767px)"
				:srcset="`${vm.BaseViewModel.MobileImageUrl}?im=Resize=(375,224)`"
			/>
			<img
				:src="`${vm.BaseViewModel.DesktopImageUrl}?im=Resize=(660,440)`"
				:alt="vm.CurrentBlock.ImageAltText || vm.CurrentBlock.Headline"
				class="c-rich-text-cta-banner__image"
			/>
		</picture>
		<VideoItemLoop
			v-else-if="vm.CurrentBlock.VideoUrl"
			:video-url="vm.CurrentBlock.VideoUrl"
			:headline="formattedHeadline"
		/>
		<div class="c-rich-text-cta-banner__content">
			<DsText v-if="vm.CurrentBlock.Headline"
					html-element="div"
					text-type="heading-4xl"
					class="c-rich-text-cta-banner__headline"
			>
				<span v-html="vm.CurrentBlock.Headline"></span>
			</DsText>
			<DsText v-if="vm.CurrentBlock.Description"
					html-element="div"
					text-type="heading-lg"
					text-style="regular"
					class="c-rich-text-cta-banner__description"
			>
				<span v-html="vm.CurrentBlock.Description"></span>
			</DsText>
			<div class="c-rich-text-cta-banner__button">
				<!--Cta Button or Cta button with formKit popup-->
				<slot></slot>
			</div>
		</div>
	</section>
</template>
